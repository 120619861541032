




























































import { Component } from 'vue-property-decorator'
import InfoBox from '@/components/hszg/InfoBox.vue'
import { Action } from 'vuex-class'
import { GET_JOB_FINDER_RESULTS } from '@/store/kode/actions'
import { JobFinderType, KodeStatusDTO } from '@/store/kode/types'
import { GET_PROFILE_KODE_STATUS } from '@/store/user/actions.type'
import BaseForm from '@/components/forms/BaseForm.vue'
import { Form } from '@/store/commonFormInput/types'
import InputTypes from '@/common/formTypes'
import { JobData } from '@/store/jobAd/types'
import JobFinderList from '@/components/kode/JobFinderList.vue'
import MetaComponent from '@/common/MetaComponent.vue'
@Component({
  components: { JobFinderList, BaseForm, InfoBox }
})
export default class JobSearch extends MetaComponent {
  @Action(GET_PROFILE_KODE_STATUS)
  public getProfileKodeStatus: () => Promise<KodeStatusDTO>

  @Action(GET_JOB_FINDER_RESULTS)
  public getJobFinderResults: () => Promise<Array<JobFinderType>>

  loadStatus = ''
  error = {}
  requestFailed = false
  kodeStatus = ''

  private jobConfig: JobData = {
    title: ''
  }

  jobFinderResult: Array<JobFinderType> = []

  get fields (): Form[] {
    return [
      {
        id: 'title',
        key: 'title',
        type: InputTypes.TEXT,
        label: this.$i18n.t('job-ad.title').toString(),
        md: 12
      }
    ]
  }

  private loadData (): void {
    this.loadStatus = 'LOADING'
    this.requestFailed = false
    this.getJobFinderResults().then(data => {
      this.jobFinderResult = data.filter(entry => {
        return entry.competenceProfileType === 'JOB_FAMILY' && entry.jobTitle.toLowerCase().includes(this.jobConfig.title.toLowerCase())
      })
    }).catch(error => {
      this.error = error
      this.requestFailed = true
    }).finally(() => {
      this.loadStatus = 'FINISH'
    })
  }

  private search (): void {
    this.loadData()
  }

  loadKodeStatusAndCompetencesForImprovement (): void {
    this.$root.$emit('load')
    this.getProfileKodeStatus().then(data => {
      this.kodeStatus = data.kodeStatus
    }).finally(() => {
      this.$root.$emit('end-load')
    })
  }

  created (): void {
    this.loadKodeStatusAndCompetencesForImprovement()
  }
}
